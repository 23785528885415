import { OverlayModule, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalModule } from '@angular/cdk/portal';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    effect,
    inject,
    input,
    signal, untracked,
} from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IUserBadges } from 'app/core/models/user/user';
import { EAclFunction, EAclModule } from 'app/core/permissions/enum/access.enum';
import { KiwidPermissionsModule } from 'app/core/permissions/kiwid-permissions.module';
import { BaseComponent } from 'app/layout/common/base/base.component';
import { NotificationChatComponent } from 'app/layout/common/messages/notification-chat/notification-chat.component';
import { ISidebarConfig } from 'app/layout/components/sidebar/sidebar-config.interface';
import { SidebarService } from 'app/layout/components/sidebar/sidebar.service';
import { Subject, first } from 'rxjs';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { locale as portuguese } from './i18n/pt';
import { hasPermission } from 'app/core/permissions/service/kiwid-permissions.service';

@Component({
    selector: 'messages',
    templateUrl: './messages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'messages',
    imports: [
        RouterModule,
        OverlayModule,
        PortalModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatBadgeModule,
        KiwidPermissionsModule,
    ]
})
export class MessagesComponent extends BaseComponent implements OnInit, OnDestroy {
    // inject
    readonly translateService = inject(TranslateService);
    private readonly sidebarService = inject(SidebarService);

    protected userBadges = signal<IUserBadges>({});
    protected setUserBadges = input<IUserBadges>(null, { alias: 'userBadges' });
    protected readonly hasPermissionChat = signal(hasPermission({
        routeAccess: [{
            mod: EAclModule.CHAT,
            fns: [EAclFunction.LIST],
        }],
    }))

    // private _overlayRef: OverlayRef;
    // private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor() {
        super();
        this.registerOnTranslate('CHAT', portuguese, english, spanish, false);

        effect(() => {
            const userBadges = this.setUserBadges();

            untracked(() => {
                this.userBadges.set(userBadges);
            })
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

    }

    /**
     * On destroy
     */
    override ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        // this._unsubscribeAll.next(null);
        // this._unsubscribeAll.complete();
        //
        // // Dispose the overlay
        // if (this._overlayRef) {
        //     this._overlayRef.dispose();
        // }
        super.ngOnDestroy();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    clickChat(): void {
        const config: ISidebarConfig = {
            component: new ComponentPortal<NotificationChatComponent>(NotificationChatComponent),
            typeHeader: 'primary',
            title: this.translateKey('SIDEBAR.TITLE'),
        };
        this.sidebarService.registerSidebar(config);

        this.subscriptions.add(
            this.sidebarService
                .componentRefObservable<NotificationChatComponent>()
                .pipe(first())
                .subscribe((ref) => {
                }),
        );
        this.sidebarService.open();
    }
}
